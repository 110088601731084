<template>
  <footer class="flex flex-col justify-center bg-o_purple-100 text-t_white text-lg py-7 pb-9 rounded-2xl">
      <p class="mt-2 text-center" v-for="info in footers" :key="info">{{ info }}</p>
      <a target="_blank" class="mt-2 text-center hover:underline transition:ease-out duration-300" href="https://www.ricehouse-blockchain-record.ntu.asia/">手機版簡易頁面</a>
      <a target="_blank" class="mt-2 text-center hover:underline transition:ease-out duration-300" href="https://www.ricehouse-traceability-en.ntu.asia/">English Version-Desktop Page</a>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      footers: [
        '行政院農業部 計畫補助',
        '國立台灣大學生物環境系統工程學系 維運',
        '106 台北市大安區羅斯福路四段1號',
        // '服務電話：+886-2-33663468',
        '版本: 2.0.0 更新於 2024/12'
      ]
    }
  }
}
</script>

<style scoped>

</style>
