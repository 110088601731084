<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="px-2">
    <!-- controlling area   -->
    <div class="sticky top-0 bg-gray-50 border-b-4 pb-1.5">
      <div class="flex pt-2">
      <div class="flex flex-row items-center ml-4">
        <label id="listbox-label1" class="block text-3xl text-o_purple-100 font-semibold mr-2">
          產品
        </label>
        <div class="relative inline-block text-left">
          <div>
            <button v-on:click="isActive1 = !isActive1" type="button"
                    class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_state1 }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <!--
            Dropdown panel, show/hide based on dropdown state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
          <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div class="py-1 max-h-80 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a @click="selectProductEvent"
                 v-for="product in productList" :key="product"
                 href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                {{ product }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="searchBar2" class="flex flex-row items-center ml-4">
        <label id="listbox-label2" class="block text-3xl font-semibold text-o_purple-100 mr-2">
          農場批號
        </label>
        <div class="relative inline-block text-left">
          <div>
            <button v-on:click="isActive2 = !isActive2" type="button"
                    class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm
                    px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100"
                    id="options-menu2" aria-haspopup="true" aria-expanded="true">
              {{ select_state2 }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>

          <!--
            Dropdown panel, show/hide based on dropdown state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
          <div v-show="isActive2" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div class="py-1 max-h-80 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a @click="selectEvent2"
                 v-for="productCode in productCodeList" :key="productCode"
                 href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                {{ productCode }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <button @click="clear"
              v-if="clearActive"
              type="button"
              class=" ml-3 font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
        清除
      </button>
      <button @click="MaskGraph(nodeName)"
              v-if="node_selected"
              type="button"
              class=" ml-3 font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
        遮蔽
      </button>
      <button @click="UpLevel(nodeName)"
              v-if="node_selected"
              type="button"
              class=" ml-3 font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
        往前
      </button>
      <button @click="DownLevel(nodeName)"
              v-if="node_selected"
              type="button"
              class=" ml-3 font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
        往後
      </button>
      <button @click="BackAllGraph"
              v-if="node_selected"
              type="button"
              class=" ml-3 font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
        返回
      </button>
      <button @click="hideNodeTest"
              v-if="false"
              type="button"
              class=" ml-3 font-semibold
              inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md
              shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-o_purple-100">
        hide
      </button>
      </div>
    </div>
    <div v-show="isLoading" wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">處理中...</h2>
      <p class="w-1/3 text-center text-white">這需要一些時間, 請不要關閉此頁面或做其他操作</p>
    </div>
    <!-- content area   -->
    <div class="grid grid-cols-8 grid-rows-6 gap-1.5 mt-5 custom-h-inside">
      <!-- node info     -->
      <div class="grid grid-rows-2 col-span-3 row-span-full gap-1.5">
        <div class="rounded-2xl bg-white shadow-2xl col-span-full row-span-1 overflow-auto mx-2 mt-2 p-6 tableScrollbar">
          <h1 class="text-o_purple-200 text-3xl font-semibold mb-5">節點解說</h1>
          <h1 v-show="detailActive" class="text-o_purple-200 text-3xl font-semibold mb-2">
            {{ nodeName }}
          </h1>
          <div v-show="detailTermActive" class="text-o_purple-100 text-3xl my-3">
            <span class="font-semibold">詞彙多國翻譯(英/中/日):</span><br>
            {{ node_term_current.chinese }}/ {{ node_term_current.english }}/ {{ node_term_current.japanese }}
          </div>
          <ul v-show="detailActive" class="text-o_purple-100 text-3xl">
            <li class="mb-1">
              <span class="font-semibold">內容:</span><br>
              {{ node_info_current.content }}
            </li>
            <li v-show="aiDetailTypeActive" class="break-all mb-1">
              <span class="font-semibold">AI碳排代號ISO14064-1:</span><br>
              {{aiDetailTypeCode  === '' ? '操作尚未產生AI碳排分類代號' : aiDetailTypeCode}}
            </li>
            <li v-show="aiDetailTypeActive" class="break-all">
              <span class="font-semibold">AI碳排類別ISO14064-1:</span><br>
              {{aiDetailTypeLabel  === '' ? '操作尚未產生AI碳排分類類別' : aiDetailTypeLabel}}
            </li>
          </ul>
        </div>
        <div class="bg-white shadow-2xl rounded-2xl p-6 mx-2 my-2 rows-span-1 overflow-auto tableScrollbar">
          <h1 class="text-o_purple-200 text-3xl font-semibold mb-5">操作紀錄</h1>
          <h1 v-show="recordActive" class="text-o_purple-200 text-3xl font-semibold">
            {{ nodeName }}
          </h1>
          <ul v-show="recordActive" class="text-o_purple-100 text-3xl my-3">
            <li v-for="(record, i) in records" :key="i" class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-b-4 border-t_dark_brown">
              <h1 class="text-right">
                  <span v-if="record.verified" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    已驗證
                  </span>
                  <span v-if="!record.verified" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">
                    未驗證
                  </span>
                  <span v-if="record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">
                    已註銷
                  </span>
                  <span v-if="!record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    未註銷
                  </span>
              </h1>
              <span class="font-semibold">此操作碳排放:</span><br>
              <span class="text-xl font-bold text-o_green">每公斤作物產生碳(kg CO2e/kg)</span><br>
              <div class="pb-3.5">0.00988</div>
              <span class="font-semibold">產品批號:</span><br>
                <div class="pb-3.5">{{ record.product_code }}</div>
              <span class="font-semibold">操作細節:</span><br>
                  <div class="pb-3.5">{{ record.detail }}</div>
              <span class="font-semibold">時間:</span><br>
                  <div class="pb-3.5">{{ record.time }}</div>
              <span class="font-semibold">地點:</span><br>
                  <div class="pb-3.5">{{ record.place }}</div>
            </li>
          </ul>
        </div>
      </div>

      <!--  graph canvas   -->
      <div id="graph" class="rounded-2xl bg-gray-400 shadow-2xl mx-2 my-2 col-span-5 row-span-full overflow-auto p-6 tableScrollbar" ref="canvas">
        <h1 class="text-o_purple-200 text-3xl font-semibold">知識圖譜</h1>
      </div>
<!--      <div v-if="false">暫定之後operationlist area</div>-->
    </div>
  </div>
</template>

<script>
import G6 from '@antv/g6'
import WZoom from 'vanilla-js-wheel-zoom/src/wheel-zoom'
import Axios from 'axios'
import Config from '@/config/config'
let graphInstance = {}
export default {
  name: 'Platform',
  components: {},
  data: function () {
    return {
      node_infos: {},
      node_info_current: {},
      node_term_current: {},
      records: {},
      unselected_classes: ['mt-2', 'p-3'],
      selected: true,
      unselected: false,
      showImageList: true,
      productList: [],
      productCodeList: [],
      graphObject: {},
      select_state1: '請選擇',
      select_state2: '請選擇',
      searchBar2: false,
      isActive1: false,
      isActive2: false,
      clearActive: false,
      imageGalleryActivate: false,
      productChainLink: '',
      nodeActive: false,
      nodeName: '',
      detailActive: false,
      recordActive: false,
      detailTermActive: false,
      aiDetailTypeActive: false,
      aiDetailTypeLabel: '',
      aiDetailTypeCode: '',
      current_graph: {},
      mask_graph: {},
      up_count: 0,
      down_count: 0,
      node_selected: false,
      isLoading: false
    }
  },
  created: function () {
    // 第一個選單farms農場選項，table格式會是第一欄是farm field第二欄是{"總季-productName":["季-農頻-productName" ...]}
    // 因此解析上要先把總季的key取出，再來iterate窮取後面的list
    this.GetProductList()
    this.node_infos = this.$store.state.nodeInfo
    // ------------會偵測url是否有product參數，若有自動選取，反之不做事
    const url = new URL(window.location)
    if (url.searchParams.get('product')) {
      this.selectProductEventByURL(url.searchParams.get('product'))
    }
  },
  mounted () {
    this.$store.state.current_graph_data = {}
    // 我個人覺得我這邊用nextTick有點怪，因為mounted階段已經有DOM了
    this.$nextTick(function () {
      // 動態抓取element寬高的function
      const width = this.$refs.canvas.clientWidth
      const height = this.$refs.canvas.clientHeight
      this.initG6(width, height, this.$store.state.current_graph_data)
    })
    // WZoom放在mounted hook合理，因為他要等DOM建立才能作用
    this.$nextTick(function () {
      WZoom.create('#myContent', {
        // type content: `image` - only one image, `html` - any HTML content
        type: 'image',
        // for type `image` computed auto (if width set null), for type `html` need set real html content width, else computed auto
        width: 1280,
        // for type `image` computed auto (if height set null), for type `html` need set real html content height, else computed auto
        height: 1024,
        // drag scrollable image
        dragScrollable: true,
        // options for the DragScrollable module
        dragScrollableOptions: {
          // smooth extinction moving element after set loose
          smoothExtinction: false,
          // callback triggered when grabbing an element
          onGrab: null,
          // callback triggered when moving an element
          onMove: null,
          // callback triggered when dropping an element
          onDrop: null
        },
        // minimum allowed proportion of scale
        minScale: null,
        // maximum allowed proportion of scale
        maxScale: 4,
        // image resizing speed
        speed: 10
      })
    })
  },
  computed: {
  },
  methods: {
    // 20220211 資訊頁面-初始取得產品清單
    // 獲得productList: 格式通常為2020-C1_Lu-Ming Rice_Tainan 16，到頁面會自動執行獲得產品清單，下拉選單用途(需要非同步處理/不用鎖畫面)
    // GET https://www.ricehouse-backend.ntu.asia/graph/product-list
    async GetProductList () {
      const productListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await productListRequest.get('/graph/product-list').then(res => {
        const resTemp = res.data
        // console.log(resTemp.productList)
        // 這類型的async task最好不要採用回傳賦值得作法，很容易導致整格程序卡住
        this.productList = resTemp.result
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    // 20220211 資訊頁面-產品選擇
    // 依據productList選擇的product去獲得相關的產品批號清單 (需要非同步處理/不用鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/graph/product-code-list
    async GetProductCodeList (product) {
      this.productCodeList = []
      this.productCodeList = this.productCodeList.concat('所有農場批號')
      const dataForm = new FormData()
      dataForm.append('product', product)
      const productCodeListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await productCodeListRequest.post('/graph/product-code-list', dataForm).then(res => {
        const resTemp = res.data
        // console.log(resTemp.productList)
        // 這類型的async task最好不要採用回傳賦值得作法，很容易導致整格程序卡住
        this.productCodeList = this.productCodeList.concat(resTemp.result)
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    // 這是若url有product參數情況會呼叫這個，在初始化就自動選產品
    async selectProductEventByURL (product) {
      // 下面這個是點擊縮、放選單
      // this.isActive1 = !this.isActive1
      // 選後把把選擇狀態顯示出來
      this.select_state1 = product
      // Product code選擇欄位初始化
      this.select_state2 = '請選擇'
      // 透過選擇的product去獲取product code list
      await this.GetProductCodeList(this.select_state1)
      // 顯示下一個選單enable
      this.searchBar2 = true
      // detailActive需要隨著切換農夫清掉、詞彙detailTermActive也要清掉 (record顯示也要清除)
      this.detailActive = false
      this.recordActive = false
      this.detailTermActive = false
      // node_selected disable會清掉mask操作按鈕(disable)
      this.node_selected = false
      // 圖譜顯示初始化清空
      this.$store.state.current_graph_data = {}
      graphInstance.read(this.$store.state.current_graph_data)
      // ----------------------------下面為將選取的內容，同步到URL上，方便使用者複製建立QR code提供商品連結到該產品的選項
      const url = new URL(window.location)
      url.searchParams.set('product', this.select_state1)
      // console.log(this.$route.name)
      // console.log(url.href)
      // url.href = url.href + this.$route.name.toLowerCase()
      // console.log(url)
      window.history.pushState({}, '', url)
      // 下面這個是點擊縮、放選單，因為是自動選最後要關閉掉
      // this.isActive1 = !this.isActive1
    },
    // 20210821 上面func可以取得product list給選單，這邊則可以從選單選出一個product (此為一個點擊事件)
    async selectProductEvent (event) {
      // 下面這個是點擊縮、放選單
      this.isActive1 = !this.isActive1
      // 選後把把選擇狀態顯示出來
      this.select_state1 = event.target.innerText
      // Product code選擇欄位初始化
      this.select_state2 = '請選擇'
      // 透過選擇的product去獲取product code list
      await this.GetProductCodeList(this.select_state1)
      // 顯示下一個選單enable
      this.searchBar2 = true
      // detailActive需要隨著切換農夫清掉、詞彙detailTermActive也要清掉 (record顯示也要清除)
      this.detailActive = false
      this.recordActive = false
      this.detailTermActive = false
      // node_selected disable會清掉mask操作按鈕(disable)
      this.node_selected = false
      // 圖譜顯示初始化清空
      this.$store.state.current_graph_data = {}
      // ----------------------------下面為將選取的內容，同步到URL上，方便使用者複製建立QR code提供商品連結到該產品的選項
      const url = new URL(window.location)
      url.searchParams.set('product', this.select_state1)
      // console.log(this.$route.name)
      // console.log(url.href)
      url.href = url.href + this.$route.name.toLowerCase()
      // console.log(url)
      window.history.pushState({}, '', url)
      // ---------------------------- graph產生前，先產生url會讓它顯示比較快，因為graph產生較花時間
      graphInstance.read(this.$store.state.current_graph_data)
    },
    // 取得選單內容from database
    async GetMenuList () {
      const menuListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await menuListRequest.get('/menu/list').then(res => {
        // this.select_state1 = 'Rice House'
        this.isActive1 = !this.isActive1
        const resTemp = res.data
        const root = [Object.keys(resTemp)[0]]
        var productList = []
        productList = productList.concat(root)
        productList = productList.concat(Object.values(resTemp)[0])
        this.productCodes = productList
      })
    },
    // 20220211 資訊頁面-農產批號選擇
    // 依據農產品選擇取得該農產品的知識圖譜內容，包含所有階層 (需要非同步處理/要鎖畫面)
    // POST https://www.ricehouse-graph.ntu.asia/service/graph
    async GetGraph (name, uLevel, dLevel, product) {
      this.isLoading = true
      const dataForm = new FormData()
      dataForm.append('name', name)
      dataForm.append('u_level', uLevel)
      dataForm.append('d_level', dLevel)
      dataForm.append('product', product)
      const graphRequest = Axios.create({
        baseURL: Config.graphBackend,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await graphRequest.post('/service/graph', dataForm).then(res => {
        this.current_graph = { nodes: res.data.result.node_styles, edges: res.data.result.edges }
        // console.log(this.current_graph)
      }).catch(err => {
        if (err.response) {
          // Request made and server responded
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    // 20220211 資訊頁面-節點操作
    // 圖譜的遮蔽、往前與往後，階層呈現變化會透過此請求來實踐，為了確保流暢性是不做鎖定畫面動作，會有較好的流暢性 (需要非同步處理/不用鎖畫面)
    // POST https://www.ricehouse-graph.ntu.asia/service/graph
    async MaskGetGraph (name, uLevel, dLevel, product) {
      const dataForm = new FormData()
      dataForm.append('name', name)
      dataForm.append('u_level', uLevel)
      dataForm.append('d_level', dLevel)
      dataForm.append('product', product)
      const graphRequest = Axios.create({
        baseURL: Config.graphBackend,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await graphRequest.post('/service/graph', dataForm).then(res => {
        this.mask_graph = { nodes: res.data.result.node_styles, edges: res.data.result.edges }
        // console.log(this.current_graph)
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    async MaskGraph (name) {
      this.up_count = 0
      this.down_count = 0
      await this.MaskGetGraph(name, 0, 0, this.select_state1)
      graphInstance.read(this.mask_graph)
    },
    async UpLevel (name) {
      this.up_count += 1
      this.down_count += 1
      await this.MaskGetGraph(name, this.up_count, this.down_count, this.select_state1)
      graphInstance.read(this.mask_graph)
    },
    async DownLevel (name) {
      this.up_count -= 1
      this.down_count -= 1
      await this.MaskGetGraph(name, this.up_count, this.down_count, this.select_state1)
      graphInstance.read(this.mask_graph)
    },
    BackAllGraph () {
      graphInstance.read(this.current_graph)
    },
    // 20220211 資訊頁面-節點選擇01
    // 取得node相關資訊，譬如name/content/content_tag (需要非同步處理/不用鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/graph/node-name-ai-info
    async showDetail (nodeName) {
      this.detailActive = false
      this.detailTermActive = false
      this.node_info_current = {}
      this.node_term_current = {}
      const dataForm = new FormData()
      dataForm.append('node', nodeName)
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await userRequest.post('/graph/node-name-ai-info', dataForm).then(res => {
        this.detailActive = true
        this.node_info_current = res.data.result
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若請求失敗就跳出func 因為return強制要有內容，這邊只好以false為回傳，不過這在瀏覽器上還是會有400或跨域錯誤顯示
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
      // 基本上能跑到下面這階段來說，那就表示上面request是成功的，我後端設計是IsErr一旦構成就會返回400，所以就會被上面catch攔截，這邊沒必要再做確認
      if (this.node_info_current.is_term) {
        this.detailTermActive = true
        // await this.getTerm(this.node_info_current.content_tag)
        this.node_term_current = {
          chinese: this.node_info_current.chinese,
          english: this.node_info_current.english,
          japanese: this.node_info_current.japanese
        }
      } else {
        this.detailTermActive = false
      }
      if (this.node_info_current.type === 'detail') {
        this.aiDetailTypeActive = true
        if (this.node_info_current.has_ai) {
          this.aiDetailTypeLabel = this.node_info_current.ai_iso_140641_label
          this.aiDetailTypeCode = this.node_info_current.ai_iso_140641_code
        } else {
          this.aiDetailTypeLabel = '此無AI碳排分類資料'
          this.aiDetailTypeCode = '此無AI碳排分類資料'
        }
      } else {
        this.aiDetailTypeActive = false
      }
    },
    // 20230825 資訊頁面-節點選擇03 (目前改以/node-name-info一併取得詞彙，減少不必要的API request)
    // 取得各國翻譯詞彙，不用鎖螢幕，選擇的節點觸發，若是該節點為term則會有相關內容的獲取 (需要非同步處理/不用鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/graph/term-chinese
    async getTerm (chinese) {
      const dataForm = new FormData()
      dataForm.append('chinese', chinese)
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await userRequest.post('/graph/term-chinese', dataForm).then(res => {
        this.node_term_current = res.data.result
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    // 20220211 資訊頁面-節點選擇02
    // 透過detail節點取得操作紀錄(若非detail節點結果是null)
    // POST https://www.ricehouse-backend.ntu.asia/service/product-detail-all-records
    async showRecords (product, detail) {
      this.recordActive = false
      const dataForm = new FormData()
      dataForm.append('product', product)
      dataForm.append('detail', detail)
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await userRequest.post('/service/product-detail-all-records', dataForm).then(res => {
        if (res.data.result) {
          this.recordActive = true
          this.records = res.data.result
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    async selectEvent2 (event) {
      this.clearActive = true
      this.select_state2 = event.target.innerText
      this.isActive2 = !this.isActive2
      // detailActive需要隨著切換農夫清掉、詞彙detailTermActive也要清掉、record顯示也要清除
      this.detailActive = false
      this.recordActive = false
      this.detailTermActive = false
      // node_selected disable會清掉mask操作按鈕(disable)
      this.node_selected = false
      // await this.GetGraph(this.select_state2, 2, 3, this.select_state1)
      if (this.select_state2 === '所有農場批號') {
        await this.GetGraph(this.select_state1, 2, 3, this.select_state1)
        // await this.GetProductBcLink()
      } else {
        await this.GetGraph(this.select_state2, 2, 3, this.select_state1)
        // await this.GetProductBcLink()
      }
      // ----------------------------下面為將選取的內容，同步到URL上，方便使用者複製建立QR code提供商品連結到該產品的選項
      const url = new URL(window.location)
      url.searchParams.set('product', this.select_state1)
      // console.log(this.$route.name)
      // console.log(url.href)
      url.href = url.href + this.$route.name.toLowerCase()
      // console.log(url)
      window.history.pushState({}, '', url)
      // ---------------------------- graph產生前，先產生url會讓它顯示比較快，因為graph產生較花時間
      graphInstance.read(this.current_graph)
    },
    clear () {
      this.searchBar2 = false
      this.clearActive = false
      this.detailActive = false
      this.recordActive = false
      this.detailTermActive = false
      this.select_state1 = '請選擇'
      this.imageGalleryActivate = false
      this.$store.state.current_graph_data = {}
      this.node_selected = false
      graphInstance.read(this.$store.state.current_graph_data)
    },
    initG6 (w, h, d) {
      const data = d
      graphInstance = new G6.Graph({
        container: 'graph',
        fitView: true,
        width: w,
        height: h,
        defaultNode: {
          // fill: '#40a9ff',
          // stroke: '#096dd9'
        },
        defaultEdge: {
          type: 'line',
          style: {
            endArrow: {
              path: G6.Arrow.triangle(10, 10),
              fill: '#413960',
              lineWidth: 3
            },
            stroke: '#413960',
            lineWidth: 1
          }
        },
        layout: {
          type: 'force',
          preventOverlap: true,
          linkDistance: 550
        },
        modes: {
          default: ['drag-canvas', 'zoom-canvas', 'drag-node'] // 允许拖拽画布、放缩画布、拖拽节点
        }
      })
      // graph.read(data)
      // graph.on('node:dragstart', function (e) {
      //   graph.layout()
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:drag', function (e) {
      //   refreshDragedNodePosition(e)
      // })
      // graph.on('node:dragend', function (e) {
      //   e.item.get('model').fx = null
      //   e.item.get('model').fy = null
      // })
      // function refreshDragedNodePosition (e) {
      //   const model = e.item.get('model')
      //   model.fx = e.x
      //   model.fy = e.y
      // }
      graphInstance.read(data)
      graphInstance.on('node:dragstart', function (e) {
        graphInstance.layout()
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:drag', function (e) {
        refreshDragedNodePosition(e)
      })
      graphInstance.on('node:dragend', function (e) {
        e.item.get('model').fx = null
        e.item.get('model').fy = null
      })
      // graphInstance.on('node:click', ev => {
      //   const nodeID = ev.item.id
      //   console.log(nodeID)
      //   this.showSomething()
      // })
      // 有時間的話會完成，選取他會highlight node並顯示node name
      // 另外會data紀錄上一個選取的node，選取到另一個就要取消掉上個style
      graphInstance.on('node:click', ev => {
        // some operations
        const nodeInstance = ev.item
        this.nodeName = nodeInstance._cfg.id
        this.node_selected = true
        this.showDetail(this.nodeName)
        this.showRecords(this.select_state1, this.nodeName)
      })
      // 針對手機平板的點擊事件，否則click對於手機平板是無效的，這個是指點完離開那瞬間
      graphInstance.on('node:touchend', ev => {
        // some operations
        const nodeInstance = ev.item
        this.nodeName = nodeInstance._cfg.id
        this.node_selected = true
        this.showDetail(this.nodeName)
        this.showRecords(this.select_state1, this.nodeName)
      })

      function refreshDragedNodePosition (e) {
        const model = e.item.get('model')
        model.fx = e.x
        model.fy = e.y
      }
    }
  }
}
</script>

<style scoped>
.custom-h-inside {
  height: 1000px;
}
/* #myWindow與#myContent是給秀圖套件用的*/
#myWindow {
  display: flex;
  /*align-items: center;*/
  /*justify-content: center;*/
  overflow: hidden;
}
#myContent {
  position: relative;
  display: flex;
  /*align-items: center;*/
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* class scrollbar背景設置 */
.tableScrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  /*底色*/
  background: #F5F5F5;
  /*漸層變化*/
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* class scrollbar滑動controller設置 */
.tableScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 150px;
  background-color: #413960;
}
/* class scrollbar corner設置(上下與左右滑動條會有個交錯正方形空白，設置這個才能處理 */
.tableScrollbar::-webkit-scrollbar-corner{
  background: #F5F5F5;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* class scrollbar使用提示，使用拉動時候會變色 */
.tableScrollbar::-webkit-scrollbar-thumb:hover {
  background: #85d0a0;
}
</style>
