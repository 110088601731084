<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="px-2">
    <!-- 操作bar   -->
    <div class="sticky top-0 z-50 bg-gray-50 border-b-4 pb-1.5 z-100">
      <div class="flex pt-2">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="fixed z-10 block relative text-3xl text-o_purple-100 font-semibold mr-2 z-20">
            產品
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="isActive1 = !isActive1" type="button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ select_state1 }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="isActive1" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <a @click="selectProductEvent($event)"
                   v-for="(product,  index) in productList" :key="'product' + index"
                   href="#" class="block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ product }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 讀取畫面 -->
    <div v-show="isLoading" wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">處理中...</h2>
      <p class="w-1/3 text-center text-white">這需要一些時間, 請不要關閉此頁面或做其他操作</p>
    </div>
    <!-- 資訊頁面: 日曆、紀錄 -->
    <div class="grid grid-cols-8 grid-rows-7 grid-flow-col gap-1.5 mt-5 custom-h-inside">
      <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 col-span-2 row-span-full overflow-auto p-6 z-0">
        <h1 class="text-o_purple-200 text-3xl font-semibold mb-5">時間日曆</h1>
        <FunctionalCalendar v-show="calendarActive"
          @dayClicked="dayClickedEvent"
          :key="markedDates"
          :is-date-range="false"
          :date-format="'yyyy-mm-dd'"
          :change-month-function="true"
          :change-year-function="true"
          :markedDates="markedDates"
          :newCurrentDate = "newCurrentDate"
          class="calendar multiple"
          ref="Calendar"
          :alwaysUseDefaultClasses="false"></FunctionalCalendar>
      </div>
      <div class="grid grid-rows-7 col-span-6 row-span-full gap-1.5">
        <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 row-span-3 overflow-auto p-6" ref="canvas">
          <h1 class="text-o_purple-200 text-3xl font-semibold">
            操作紀錄 <span v-show="recordTimeActive"> {{ selectedTime }} </span>
          </h1>
          <ul v-show="recordTimeActive" class="text-o_purple-100 text-3xl my-3">
            <li v-for="(record, index) in recordTime" :key="'record' + index" class="text-o_purple-100 text-3xl mb-4  mt-1 pt-4 border-b-4 border-t_dark_brown">
              <h1 class="text-right">
                  <span v-if="record.verified" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    已驗證
                  </span>
                <span v-if="!record.verified" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">
                    未驗證
                  </span>
                <span v-if="record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-red-100 text-red-800">
                    已註銷
                  </span>
                <span v-if="!record.error" class="mr-3 px-6 text-2xl leading-15 font-semibold rounded-full bg-green-100 text-green-800">
                    未註銷
                  </span>
              </h1>
              <span class="font-semibold">產品批號:</span><br>
              <div class="pb-3.5">{{ record.product_code }}</div>
              <span class="font-semibold">操作細節:</span><br>
              <div class="pb-3.5">{{ record.detail }}</div>
              <span class="font-semibold">時間:</span><br>
              <div class="pb-3.5">{{ record.time }}</div>
              <span class="font-semibold">地點:</span><br>
              <div class="pb-3.5">{{ record.place }}</div>
            </li>
          </ul>
        </div>
        <div class="rounded-2xl bg-white shadow-2xl mx-2 my-2 row-span-4 overflow-auto p-6" ref="canvas">
          <h1 class="text-o_purple-200 text-3xl font-semibold">區塊鏈紀錄清單</h1>
          <div class="flex flex-col m-3 my-6">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div v-show="tableActive" class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-5">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Id
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        時間
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        地點
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        操作
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        細節
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        產品
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        產品批號
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        驗證
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        註銷
                      </th>
                      <th scope="col" class="relative px-4 py-3">
                        <span class="sr-only">註銷</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="(content, index) in contents" :key="'content' + index">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.record_id }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.time }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.place }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.operation }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.detail }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.product }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ content.product_code }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="!content.verified" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    未驗證
                  </span>
                        <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    已驗證
                  </span>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="!content.error" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    未註銷
                  </span>
                        <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    已註銷
                  </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios'
import Config from '@/config/config'
// 日曆相關
import { FunctionalCalendar } from 'vue-functional-calendar'
export default {
  name: 'Records',
  methods: {
    // 20220214 操作紀錄頁面-初始取得產品清單
    // 獲得productList: 初始自動取得產品清單，為GET，到頁面會自動執行獲得產品清單，下拉選單用途(需要非同步處理/不用鎖畫面)
    // GET https://www.ricehouse-backend.ntu.asia/graph/product-list
    async GetProductList () {
      const productListRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await productListRequest.get('/graph/product-list').then(res => {
        const resTemp = res.data
        // console.log(resTemp.productList)
        // 這類型的async task最好不要採用回傳賦值得作法，很容易導致整格程序卡住
        this.productList = resTemp.result
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    // 這是若url有product參數情況會呼叫這個，在初始化就自動選產品
    async selectProductEventByURL (product) {
      // 下面這個是點擊縮、放選單
      // this.isActive1 = !this.isActive1
      this.tableActive = false
      this.recordTimeActive = false
      // 選後把把選擇狀態顯示出來
      this.select_state1 = product
      await this.GetRecordContentWithoutAuthByNodeTag(this.select_state1, 'product')
      this.calendarActive = false
      await this.GetRecordDates(this.select_state1)
      // ----------------------------下面為將選取的內容，同步到URL上，方便使用者複製建立QR code提供商品連結到該產品的選項
      const url = new URL(window.location)
      url.searchParams.set('product', this.select_state1)
      // console.log(this.$route.name)
      // console.log(url.href)
      // url.href = url.href + this.$route.name.toLowerCase()
      // console.log(url)
      window.history.pushState({}, '', url)
      // 下面這個是點擊縮、放選單，因為是自動選最後要關閉掉
      // this.isActive1 = !this.isActive1
    },
    async selectProductEvent (event) {
      // 下面這個是點擊縮、放選單
      this.isActive1 = !this.isActive1
      this.tableActive = false
      this.recordTimeActive = false
      // 選後把把選擇狀態顯示出來
      this.select_state1 = event.target.innerText
      await this.GetRecordContentWithoutAuthByNodeTag(this.select_state1, 'product')
      this.calendarActive = false
      await this.GetRecordDates(this.select_state1)
      // ----------------------------下面為將選取的內容，同步到URL上，方便使用者複製建立QR code提供商品連結到該產品的選項
      const url = new URL(window.location)
      url.searchParams.set('product', this.select_state1)
      // console.log(this.$route.name)
      // console.log(url.href)
      url.href = url.href + this.$route.name.toLowerCase()
      // console.log(url)
      window.history.pushState({}, '', url)
    },
    async dayClickedEvent (val) {
      // console.log('hi!')
      // console.log(val.date)
      const date = new Date(val.date)
      var year = date.getFullYear()
      var month = String(date.getMonth() + 1).padStart(2, '0')
      var day = String(date.getDate()).padStart(2, '0')
      var dayString = year + '-' + month + '-' + day
      // console.log('%s-%s-%s', year, month, day)
      await this.showRecordsByDate(dayString, this.select_state1)
    },
    // 這可以指定calendar到指定日期view
    goToTheDay (date) {
      this.$refs.Calendar.ChooseDate(date)
    },
    // 20220214 操作紀錄頁面-日期選擇-該日所有操作細節取得
    // 日曆選取日期取得相關的操作紀錄要配合產品選擇，這個量比較少，而且會有很多日期可以在日曆上可供選取，會有較高的內容交互切換的頻率，因此沒有鎖畫面
    // POST https://www.ricehouse-backend.ntu.asia/service/get-dates-by-product
    async showRecordsByDate (day, product) {
      this.recordTimeActive = false
      this.selectedTime = day
      const dataForm = new FormData()
      dataForm.append('day', day)
      dataForm.append('product', product)
      const userRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'html/text' 表示要接收的是text
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' }
      })
      await userRequest.post('/service/get-records-by-day', dataForm).then(res => {
        if (res.data.result) {
          this.recordTimeActive = true
          this.recordTime = res.data.result
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          return false
        }
      })
    },
    // 20220214 操作紀錄頁面-選擇產品-操作紀錄取得
    // 取得選擇得產品相關的所有操作紀錄，會鎖畫面 (需要非同步處理/要鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/service/node-records
    async GetRecordContentWithoutAuthByNodeTag (node, tag) {
      this.isLoading = true
      this.tableActive = false
      // console.log('Getting record content ...')
      const dataForm = new FormData()
      dataForm.append('node', node)
      dataForm.append('tag', tag)
      const nodeInfoRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await nodeInfoRequest.post('/service/node-records', dataForm).then(res => {
        const resTemp = res.data
        // 這個API回傳僅有一個result array因此錯誤為null反之則有內容，有內容就執行
        if (resTemp) {
          this.contents = resTemp.result
          this.tableActive = true
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    // 20220214 操作紀錄頁面-選擇產品-操作日期取得
    // 會取得該產品的所有操作紀錄時間，註記月曆上，會鎖畫面，是跟取得操作紀錄一起運作，單純抓紀錄沒有過多操作，因此二者都會鎖住畫面，不影響整個操作體驗(需要非同步處理/要鎖畫面)
    // POST https://www.ricehouse-backend.ntu.asia/service/get-dates-by-product
    async GetRecordDates (product) {
      this.isLoading = true
      const dataForm = new FormData()
      dataForm.append('product', product)
      const recordDatesRequest = Axios.create({
        baseURL: Config.basicBackendBaseURL,
        // Accept: 'application/json' 表示要接收的是JSON
        headers: { Accept: 'application/json' }
      })
      await recordDatesRequest.post('/service/get-dates-by-product', dataForm).then(res => {
        const resTemp = res.data
        // 這個API回傳僅有一個result array因此錯誤為null反之則有內容，有內容就執行
        if (resTemp) {
          this.markedDates = resTemp.result
          const smallestDate = new Date(this.markedDates[0])
          this.newCurrentDate = smallestDate
          this.calendarActive = true
        }
      }).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    }
  },
  mounted () {
    this.GetProductList()
    // this.goToTheDay('2021-9-15')
    // ------------會偵測url是否有product參數，若有自動選取，反之不做事
    const url = new URL(window.location)
    if (url.searchParams.get('product')) {
      this.selectProductEventByURL(url.searchParams.get('product'))
    }
  },
  data () {
    return {
      selectedTime: '',
      recordTimeActive: true,
      calendarActive: false,
      tableActive: false,
      recordTime: {},
      isActive1: false,
      isLoading: false,
      productList: [],
      contents: {},
      newCurrentDate: new Date(2021, 10, 10),
      select_state1: '請選擇',
      // 日曆相關
      markedDates: []
    }
  },
  components: {
    // 日曆相關
    FunctionalCalendar
  }
}
</script>

<style scoped>
.custom-h-inside {
  height: 1000px;
}
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
